<template>
    <div class="home-container">
        <el-carousel height="720px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <div class="text-container">
                    <div class="text1">
                        {{ item.text1 }}
                    </div>
                    <div class="text2">
                        {{ item.text2 }}
                    </div>
                </div>
                <img :src="item.url" alt="">
            </el-carousel-item>
        </el-carousel>
        <div class="custom-reason-container">
            <div class="title">为什么要定制开发</div>
            <div class="custom-reason-content">
                <div class="custom-reason-item">
                    <img src="@/assets/custom-reason1.png" alt="">
                    <div class="title">信息化系统对接</div>
                    <div class="intro">将ERP、OMS、CRM等内部系统与电商、广告等外部系统打通</div>
                </div>
                <div class="custom-reason-item">
                    <img src="@/assets/custom-reason2.png" alt="">
                    <div class="title">满足一致视觉要求</div>
                    <div class="intro">可让资深UI设计师根据品牌一致性原则对产品视觉进行深度定制</div>
                </div>
                <div class="custom-reason-item">
                    <img src="@/assets/custom-reason3.png" alt="">
                    <div class="title">拥有版权和数据</div>
                    <div class="intro">真正拥有属于你自己的电商产品，独立部署独立域名独立数据库</div>
                </div>
                <div class="custom-reason-item">
                    <img src="@/assets/custom-reason4.png" alt="">
                    <div class="title">企业数据资产化</div>
                    <div class="intro">拥有并共享任何数据，解决信息孤岛问题，实现企业数据资产化</div>
                </div>
            </div>
        </div>
        <div class="product-container">
            <div class="title">定制开发优势</div>
            <!-- <div class="intro">高端品牌形象设计与传播</div> -->
            <div class="product-list">
                <div class="product-item">
                    <div class="label-container">阶段确认，迭代推进</div>
                    <div class="product-content">
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>专业的产品沟通</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>国际化的UI设计</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>按迭代验收质量</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>阶段交付物确认</span>
                        </div>
                    </div>
                </div>
                <div class="product-item">
                    <div class="label-container">专人专职，极简沟通</div>
                    <div class="product-content">
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>20+项目管理经验</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>只需与项目经理沟通</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>成员权责边界清晰</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>充分的沟通历史记录</span>
                        </div>
                    </div>
                </div>
                <div class="product-item">
                    <div class="label-container">即时反馈，进度透明</div>
                    <div class="product-content">
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>发现问题随时沟通</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>持续更新项目计划</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>专业项目管理软件</span>
                        </div>
                        <div class="content-item">
                            <img src="@/assets/icon-hook.png" alt="">
                            <span>开发进度随时可看</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-recommend-container">
            <div class="title">优秀服务推荐</div>
            <div class="service-recommend-list">
                <div class="service-recommend-item">
                    <img src="@/assets/service-recommend1.png" alt="">
                    <div class="text">企业内部管理系统</div>
                </div>
                <div class="service-recommend-item">
                    <img src="@/assets/service-recommend2.png" alt="">
                    <div class="text">B2C与B2B2C线上商城<br />PC端/小程序端</div>
                </div>
                <div class="service-recommend-item">
                    <img src="@/assets/service-recommend3.png" alt="">
                    <div class="text">在线教育APP、直播、录播课程<br />班级报名与在线答题</div>
                </div>
                <div class="service-recommend-item">
                    <img src="@/assets/service-recommend4.png" alt="">
                    <div class="text">在线考试、答题、阅卷系统<br />成绩统计与发证管理</div>
                </div>
                <div class="service-recommend-item">
                    <img src="@/assets/service-recommend5.png" alt="">
                    <div class="text">职业教育、 教培行业、在线<br />教育、在线考试、教务管理</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            bannerList: [{
                url: require('@/assets/banner1.png'),
                text1: '一站式软件定制开发服务',
                text2: '软件开发外包 ｜ 软件项目承接 ｜ 自营SaaS产品',
            }, {
                url: require('@/assets/banner2.png'),
                text1: '专业方案满足企业需求',
                text2: '用优秀的人，做优秀的产品',
            }]
        }
    },
    methods: {},
    created() { }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {

    .el-carousel__item {
        >img {
            height: 720px;
        }

        .text-container {
            position: absolute;
            padding-top: 258px;
            padding-left: 326px;

            .text1 {
                font-weight: normal;
                font-size: 82px;
                color: #1F2F4E;
                line-height: 112px;
            }

            .text2 {
                margin-top: 20px;
                font-weight: 400;
                font-size: 32px;
                color: #2350F6;
                line-height: 45px;
            }
        }
    }
}

.custom-reason-container {
    width: 1180px;
    margin: 120px auto;

    >.title {
        margin-bottom: 70px;
        font-weight: 500;
        font-size: 36px;
        color: #000000;
        line-height: 50px;
        text-align: center;
    }

    .custom-reason-content {
        display: flex;
        justify-content: space-between;

        .custom-reason-item {
            width: 256px;
            display: flex;
            flex-direction: column;
            align-items: center;

            >img {
                height: 216px;
                width: 216px;
            }

            >.title {
                margin-top: 30px;
                font-weight: 600;
                font-size: 18px;
                color: #333333;
                line-height: 25px;
            }

            >.intro {
                margin-top: 18px;
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                line-height: 22px;
            }
        }
    }
}

.product-container {
    text-align: center;
    width: 1280px;
    margin: 120px auto 60px;

    >.title {
        font-weight: 500;
        font-size: 36px;
        color: #000000;
        line-height: 50px;
    }

    >.intro {
        margin-top: 20px;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
    }

    .product-list {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

        .product-item {
            text-align: center;
            width: 400px;
            height: 356px;
            border: 1px solid #EEEEEE;

            .label-container {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: content-box;
                border-top: 5px solid rgba(76, 134, 223, 0.5);
                width: 100%;
                height: 120px;
                background: url('@/assets/custom-bg.png') no-repeat;
                background-size: cover;
                font-weight: 500;
                font-size: 32px;
                color: #000000;
                line-height: 45px;
            }

            .product-content {
                padding: 30px 0 0 56px;
            }

            .content-item {
                text-align: left;
                margin-bottom: 28px;

                >img {
                    margin-right: 20px;
                    width: 22px;
                    height: 16px;
                }

                >span {
                    font-weight: 400;
                    font-size: 16px;
                    color: #000000;
                    line-height: 22px;
                }
            }
        }
    }
}

.service-recommend-container {
    margin-bottom: 120px;

    >.title {
        margin-bottom: 70px;
        text-align: center;
        font-weight: 500;
        font-size: 36px;
        color: #000000;
        line-height: 50px;
    }

    .service-recommend-list {
        display: flex;

        .service-recommend-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 20%;

            >img {
                width: 100%;
            }

            >.text {
                text-align: center;
                margin-top: 30px;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                line-height: 25px;
            }
        }
    }

}
</style>
