<template>
    <div class="layout-container">
        <div class="header-container">
            <router-link to="/">
                <div class="logo">弘法信息技术</div>
            </router-link>
            <!-- <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal">
                <el-menu-item index="/home">首页</el-menu-item>
                <el-menu-item index="/service">案例</el-menu-item>
            </el-menu> -->
            <ul class="navbar">
                <li>
                    <span @click="handleMenu('/home')" :class="activeIndex == '/home' ? 'active' : ''">首页</span>
                </li>
                <li>
                    <span @click="handleMenu('/service')" :class="activeIndex == '/service' ? 'active' : ''">服务</span>
                </li>
            </ul>
        </div>
        <router-view />
        <div class="bottom-container ">
            <div class="wrapper-1280">
                <div class="row1">
                    <div class="logo">弘法信息技术</div>
                    <div class="record">Copyright © 2023 深圳市弘法信息技术有限公司
                        <a style="color: #D2527F;" href="https://beian.miit.gov.cn/">粤ICP备2023031242号-3</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            // activeIndex: this.$route.path
        }
    },
    computed: {
        activeIndex() {
            return this.$route.path
        }
    },
    methods: {
        handleMenu(path) {
            this.$router.push(path)
        }
    },
    created() { }
}
</script>

<style lang="scss" scoped>
.layout-container {
    .header-container {
        height: 72px;
        padding: 0 320px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            font-weight: 500;
            font-size: 24px;
            color: #000000;
            line-height: 33px;
        }

        .navbar {
            display: flex;

            >li {
                margin-right: 70px;


                >a {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 25px;
                    color: #000000;
                }

                >span {
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 25px;
                    color: #000000;
                }

                .active {
                    font-weight: 600;
                }
            }
        }
    }

    .bottom-container {
        display: flex;
        align-items: center;
        // padding-top: 60px;
        height: 100px;
        background: #2F3031;

        .row1 {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 33px;
        }

        .row2 {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 20px;
        }

        .record {
            margin-left: 85px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 20px;
        }
    }
}
</style>
