<template>
  <div id="app">
    <layout />
  </div>
</template>

<script>
import layout from '@/views/layout/index.vue'

export default {
  name: 'App',
  components: {
    layout
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

.wrapper-1280 {
  width: 1280px;
  margin: 0 auto;
}

ul li {
  list-style-type: none;
  /* 去掉圆点 */
}
</style>
